* {
  box-sizing: border-box;
}

body {
  font-family: "Karla", sans-serif;
  margin: 0;
  background-color: rgb(34, 33, 33);
}

main {
  padding: 36px;
}

.header {
  display: flex;
  align-items: center;
  height: 65px;
  background: linear-gradient(90.41deg, #6e1936 1.14%, #106d42 100%);
  color: white;
  padding: 20px;
}

.header--image {
  height: 100%;
  margin-right: 6px;
}

.header--title {
  font-size: 1.25rem;
  margin-right: auto;
}

.header--project {
  font-size: 0.75rem;
  font-weight: 500;
}

.form {
  display: grid;
  grid-template: 40px 40px / 1fr 1fr;
  gap: 17px;
  margin-bottom: 17px;
}

.form--input {
  font-family: "Karla", sans-serif;
  border-radius: 5px;
  border: 1px solid #d5d4d8;
  text-indent: 5px;
}

.form--button {
  grid-column: 1 / -1;
  font-family: "Karla", sans-serif;
  border-radius: 5px;
  background: linear-gradient(90.41deg, #f53a78 1.14%, #18da7f 100%);
  color: white;
  border: none;
  cursor: pointer;
}

.meme {
  position: relative;
  justify-content: center;
  display: block;
}

.meme--image {
  max-width: 70%;
  border-radius: 10px;
  display: block;
  margin: auto;
  border: #ffa0bf solid 2px;
}

.meme--text {
  position: absolute;
  width: 80%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin: 15px 0;
  padding: 0 5px;
  font-family: impact, sans-serif;
  font-size: 2em;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000,
    -2px 2px 0 #000, 0 2px 0 #000, 2px 0 0 #000, 0 -2px 0 #000, -2px 0 0 #000,
    2px 2px 5px #000;
}

.bottom {
  bottom: 0;
}

.top {
  top: 0;
}
